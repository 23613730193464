import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';

const NikkeGuidesCharProgPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Character progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_5.png"
            alt="Character progression"
          />
        </div>
        <div className="page-details">
          <h1>Character progression</h1>
          <h2>
            A guide that will explain the various ways of making your characters
            stronger.
          </h2>
          <p>
            Last updated: <strong>05/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Character profile" />
              <p>
                The are various ways to make your characters in Goddess of
                Victory: NIKKE stronger - including leveling, limit breaking,
                upgrading skills. But let's not get ahead of ourselves - here's
                how the profile of an example character looks like:
              </p>
              <StaticImage
                src="../../../images/nikke/generic/profile.jpg"
                alt="Profile"
              />
              <p>Let's go over the important bits.</p>
              <ol>
                <li>
                  The most important part of the profile - the characters'
                  design,
                </li>
                <li>
                  The Rarity of the character - currently there are three
                  rarities available:
                </li>
                <ul>
                  <li>R - Rare</li>
                  <li>SR - Super Rare</li>
                  <li>SSR - Super Super Rare</li>
                </ul>
                <li>
                  Limit break indicator - it will be explained in its own
                  section later,
                </li>
                <li>
                  Burst type indicator - the actual most important part of the
                  profile that will affect how you build your team,
                </li>
                <li>Status - the stats of the character,</li>
                <li>
                  Level upgrade and Limit Break - the first option allows you to
                  increase the level of the character, and the second one allows
                  you increase the level cap (more about it below),
                </li>
                <li>
                  Information about the character, in order from the left:
                </li>
                <ul>
                  <li>Element,</li>
                  <li>Weapon,</li>
                  <li>Class,</li>
                  <li>Manufacturer.</li>
                </ul>
                <li>
                  Equipment/Skills/Cube - you can equip new gear here but also -
                  after changing to other tabs - check (and upgrade) the skills
                  and Cube.
                </li>
              </ol>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297253"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Leveling and limit breaking" />
              <h5>Leveling</h5>
              <p>
                To upgrade the level of your characters, you will need to use
                Credits and Battle Data Sets that are obtained from various
                places in the game.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/profile_upgrade.jpg"
                alt="Profile"
              />
              <p>
                Also, every 10 levels, in addition to Credits and Battle Data
                Sets, you will also require Core Dust - and Core Dust will be
                your biggest bottleneck, so always buy it from the shop if you
                see it there.
              </p>
              <p>
                As the level of your character increases, the more materials you
                will require.
              </p>
              <h5>Limit breaking</h5>
              <p>
                At the beginning of the game, no mater the rarity of the
                character, you won't be able to upgrade someone past level 80.
              </p>
              <p>
                To increase the limit you will need to get a dupe of the
                character:
              </p>
              <StaticImage
                src="../../../images/nikke/generic/profile_limit.jpg"
                alt="Profile"
              />
              <p>
                Only after 3 times limit breaking, SSR rarity character can
                increase their level limit from 160 to 200. As for R rarity
                characters, they can't be limit broken at all and SR rarity
                characters can be limit broken twice.
              </p>
              <p>
                Every time you limit break a character, a new star will appear
                in the profile.
              </p>
              <h5>Core Up</h5>
              <p>
                This system becomes available for SSR rarity characters after
                you max limit break them. Each Core Up level requires a dupe and
                it gives 2% increase to all stats.
              </p>
              <p>
                This brings the required amount of dupes to max Core up a
                character from scratch to 11.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297016"
              ></div>
              <SectionHeader title="Upgrading skills" />
              <StaticImage
                src="../../../images/nikke/generic/profile_skills.jpg"
                alt="Profile"
              />
              <p>
                You can upgrade your characters skills by using materials farmed
                from the Simulation room. All skills start at level 1 and the
                max level is 10.
              </p>
              <p>
                <strong>Important!</strong> While you can easily reset your
                character's level, it doesn't affect their skills and you won't
                get the materials you used. So only start upgrading skills of
                characters that you know are good!
              </p>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Equipment" />
              <p>
                Each character has 4 slots for equipment which you can obtain
                from random drops in stages or by farming them in Interception
                game mode. Be aware that each Class of character requires
                different gear.
              </p>
              <p>
                Equipment comes with various tiers - the higher the tier, the
                bigger stat bonus. Also, after certain point you will be able to
                obtain Manufacturer exclusive gear that gives bigger bonuses if
                you equip on a character from the same Manufacturer.
              </p>
              <p>
                Also, once you reach tier 3, you can upgrade the equipment by
                sacrificing other pieces in order to increase the bonuses.
                Alternatively, you can use Module Boosters to substitute other
                gear.
              </p>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Resetting" />
              <p>
                Because the game has a system that allows you to easily reset a
                character and get 100% of the materials used to level her up
                back, you don't have to be afraid about leveling random
                characters and testing them out.
              </p>
              <p>
                During the CBT the cost of a character reset was set to 10 gems
                (premium currency).
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Character profile</a>
                </li>
                <li>
                  <a href="#section-1">Leveling and limit breaking</a>
                </li>
                <li>
                  <a href="#section-2">Upgrading skills</a>
                </li>
                <li>
                  <a href="#section-3">Equipment</a>
                </li>
                <li>
                  <a href="#section-4">Resetting</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCharProgPage;

export const Head: React.FC = () => (
  <Seo
    title="Character Progression | NIKKE | Prydwen Institute"
    description="How to make your Goddess of Victory: NIKKE characters stronger."
  />
);
